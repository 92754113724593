import { formatDate } from '../core';
import * as actionTypes from './actionTypes';
import * as api from './api';
import cogoToast from 'cogo-toast';

export const getPickingData = (from, to) => dispatch => {
  dispatch({
    type: actionTypes.GET_PICKING_DATA.REQUEST
  })
  return api.getPickingData(formatDate(from), formatDate(to)).then(response => {
    dispatch({
      type: actionTypes.GET_PICKING_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_PICKING_DATA.ERROR
      })
    })
}

export const getSendingData = (from, to) => dispatch => {
  dispatch({
    type: actionTypes.GET_SENDING_DATA.REQUEST
  })
  return api.getSendingData(formatDate(from), formatDate(to)).then(response => {
    dispatch({
      type: actionTypes.GET_SENDING_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_SENDING_DATA.ERROR
      })
    })
}

export const getStockMoveData = (from, to) => dispatch => {
  dispatch({
    type: actionTypes.GET_STOCK_MOVE_DATA.REQUEST
  })
  return api.getStockMoveData(formatDate(from), formatDate(to)).then(response => {
    dispatch({
      type: actionTypes.GET_STOCK_MOVE_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_STOCK_MOVE_DATA.ERROR
      })
    })
}

export const getGoodsInData = (from, to) => dispatch => {
  dispatch({
    type: actionTypes.GET_GOODS_IN_DATA.REQUEST
  })
  return api.getGoodsInData(formatDate(from), formatDate(to)).then(response => {
    dispatch({
      type: actionTypes.GET_GOODS_IN_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_GOODS_IN_DATA.ERROR
      })
    })
}

export const getReturnsData = (from, to) => dispatch => {
  dispatch({
    type: actionTypes.GET_RETURNS_DATA.REQUEST
  })
  return api.getReturnsData(formatDate(from), formatDate(to)).then(response => {
    dispatch({
      type: actionTypes.GET_RETURNS_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_RETURNS_DATA.ERROR
      })
    })
}

export const getMultiTaskData = (from, to) => dispatch => {
  dispatch({
    type: actionTypes.GET_MULTI_TASK_DATA.REQUEST
  })
  return api.getMultiTaskData(formatDate(from), formatDate(to)).then(response => {
    dispatch({
      type: actionTypes.GET_MULTI_TASK_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_MULTI_TASK_DATA.ERROR
      })
    })
}

export const getStockCountData = (from, to) => dispatch => {
  dispatch({
    type: actionTypes.GET_STOCK_COUNT_DATA.REQUEST
  })

  return api.getStockCountData(formatDate(from), formatDate(to)).then(response => {
    dispatch({
      type: actionTypes.GET_STOCK_COUNT_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_STOCK_COUNT_DATA.ERROR
      })
    })
}


export const updateDashboardLayout = items => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_DASHBOARD_LAYOUT.SUCCESS,
    payload: {
      data: items
    }
  });
};

export const setAppSettings = data => dispatch => {
  try {
    dispatch({
      type: actionTypes.SET_SETTINGS.SUCCESS,
      payload: data
    });
    cogoToast.success('Success', { position: 'top-right' });
  } catch (error) {
    dispatch({ type: actionTypes.SET_SETTINGS.ERROR });
  }
};



export const getLocation = () => dispatch => {
  dispatch({
    type: actionTypes.GET_LOCATION_DATA.REQUEST
  })
  return api.getLocation().then(response => {
    dispatch({
      type: actionTypes.GET_LOCATION_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_LOCATION_DATA.ERROR
      })
    })
}

export const getSuppliers = () => dispatch => {
  dispatch({
    type: actionTypes.GET_SUPPLIERS_DATA.REQUEST
  })
  return api.getSuppliers().then(response => {
    dispatch({
      type: actionTypes.GET_SUPPLIERS_DATA.SUCCESS,
      payload: response.data
    })
  })
    .catch(error => {
      dispatch({
        type: actionTypes.GET_SUPPLIERS_DATA.ERROR
      })
    })
}

export const getStockConsumptionRateReport = (from, to, location, supplier, productType, daysStockToHold, csvInstance) => dispatch => {
  dispatch({
    type: actionTypes.GET_STOCK_CONSUMPTION_RATE_REPORT.REQUEST
  })
  return api.getStockConsumptionRateReport(formatDate(from), formatDate(to), location, supplier, productType, daysStockToHold).then(response => {
    dispatch({
      type: actionTypes.GET_STOCK_CONSUMPTION_RATE_REPORT.SUCCESS,
      payload: response.data
    })
    csvInstance && csvInstance.current.link.click();
  })
    .catch(error => {
      cogoToast.error('Error', { position: 'top-right' });
      dispatch({
        type: actionTypes.GET_STOCK_CONSUMPTION_RATE_REPORT.ERROR
      })
    })
}