import React, { useState } from 'react';
import { useTranslation } from '../../core';
import Tabs from '../../core/Components/Tabs';
import PickingDataDashboard from './PickingDataDashboard';
import 'react-daterange-picker/dist/css/react-calendar.css';
import './dashboard.css';
import '../../core/Components/DateRange/styles.scss'; // main style file
import '../../core/Components/DateRange/theme/default.scss'; // main style file
import SendingDataDashboard from './SendingDataDashboard';
import StockMoveDataDashboard from './StockMoveDataDashboard';
import GoodsDataDashboard from './GoodsDataDashboard';
import ReturnsDataDashboard from './ReturnsDataDashboard';
import MultiTaskDataDashboard from './MultiTaskDataDashboard';
import Settings from './Settings';
import MultiTaskDataTable from './MultiTaskDataTable';
import Reports from './Reports';
import StockCountDataDashboard from './StockCountDataDashboard';

const { TabPane } = Tabs

const TAB_KEYS = {
  pickingData: "pickingData",
  sendingData: "sendingData",
  stockMoveData: "stockMoveData",
  goodsInData: "goodsInData",
  returnsData: "returnsData",
  multiTaskData: "multiTaskData",
  settings: "settings",
  multiTaskDataTable: 'multiTaskDataTable',
  reports: 'reports',
  stockCount: 'stockCount'
}
const Dashboard = () => {

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(TAB_KEYS.pickingData)

  const onChange = (value) => {
    setActiveTab(value)
  }

  return (
    <Tabs activeKey={activeTab} onChange={onChange}>
      <TabPane tab={t("tabs.pickingData")} key={TAB_KEYS.pickingData}>
        {activeTab === TAB_KEYS.pickingData && <PickingDataDashboard />}
      </TabPane>
      <TabPane tab={t("tabs.sendingData")} key={TAB_KEYS.sendingData}>
        {activeTab === TAB_KEYS.sendingData && <SendingDataDashboard />}
      </TabPane>
      <TabPane tab={t("tabs.stockMoveData")} key={TAB_KEYS.stockMoveData}>
        {activeTab === TAB_KEYS.stockMoveData && <StockMoveDataDashboard />}
      </TabPane>
      <TabPane tab={t("tabs.stockCount")} key={TAB_KEYS.stockCount}>
        {activeTab === TAB_KEYS.stockCount && <StockCountDataDashboard />}
      </TabPane>
      <TabPane tab={t("tabs.goodsInData")} key={TAB_KEYS.goodsInData}>
        {activeTab === TAB_KEYS.goodsInData && <GoodsDataDashboard />}
      </TabPane>
      <TabPane tab={t("tabs.returnsData")} key={TAB_KEYS.returnsData}>
        {activeTab === TAB_KEYS.returnsData && <ReturnsDataDashboard />}
      </TabPane>
      <TabPane tab={t("tabs.multiTaskData")} key={TAB_KEYS.multiTaskData}>
        {activeTab === TAB_KEYS.multiTaskData && <MultiTaskDataDashboard />}
      </TabPane>
      <TabPane tab={t("tabs.multiTaskDataTable")} key={TAB_KEYS.multiTaskDataTable}>
        {activeTab === TAB_KEYS.multiTaskDataTable && <MultiTaskDataTable />}
      </TabPane>
      <TabPane tab={t("tabs.reports")} key={TAB_KEYS.reports}>
        {activeTab === TAB_KEYS.reports && <Reports />}
      </TabPane>
      <TabPane tab={t("tabs.settings")} key={TAB_KEYS.settings}>
        {activeTab === TAB_KEYS.settings && <Settings />}
      </TabPane>
    </Tabs>
  );
}

export default Dashboard;


