import { createActionType } from '../core';
import { NAME } from './constants';




export const UPDATE_DASHBOARD_LAYOUT = createActionType(`${NAME}/UPDATE_DASHBOARD_LAYOUT`);

export const GET_PICKING_DATA = createActionType(`${NAME}/GET_PICKING_DATA`);
export const GET_SENDING_DATA = createActionType(`${NAME}/GET_SENDING_DATA`);
export const GET_STOCK_MOVE_DATA = createActionType(`${NAME}/GET_STOCK_MOVE_DATA`);
export const GET_GOODS_IN_DATA = createActionType(`${NAME}/GET_GOODS_IN_DATA`);
export const GET_RETURNS_DATA = createActionType(`${NAME}/GET_RETURNS_DATA`);
export const GET_MULTI_TASK_DATA = createActionType(`${NAME}/GET_MULTI_TASK_DATA`);
export const GET_STOCK_COUNT_DATA = createActionType(`${NAME}/GET_STOCK_COUNT_DATA`);


export const SET_SETTINGS = createActionType(`${NAME}/SET_SETTINGS`);

export const GET_LOCATION_DATA = createActionType(`${NAME}/GET_LOCATION_DATA`);
export const GET_SUPPLIERS_DATA = createActionType(`${NAME}/GET_SUPPLIERS_DATA`);
export const GET_STOCK_CONSUMPTION_RATE_REPORT = createActionType(`${NAME}/GET_STOCK_CONSUMPTION_RATE_REPORT`);