import * as actionTypes from './actionTypes';
import * as api from './api';

export const authorize = (data) => async dispatch => {
    dispatch({
        type: actionTypes.AUTHORIZE_BY_APPLICATION.REQUEST,
        payload: data
    });
    return api.autorizeByApplication(data)
        .then(response => {
            dispatch({
                type: actionTypes.AUTHORIZE_BY_APPLICATION.SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({type: actionTypes.AUTHORIZE_BY_APPLICATION.ERROR});
        });
};
