import React, { useEffect, useState } from 'react';
import BarChart from '../../core/Components/Barchart';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useTranslation } from '../../core';
import { DateRangePicker } from "../../core/Components/DateRange";
import { addDays } from 'date-fns';
import { useSelector } from 'react-redux';
import * as dashboardSelectors from '../selectors';
import { getMultiTaskData } from '../actions';
import _ from 'lodash';
import useAction from '../../core/services/UseAction';
import Table from '../../core/Components/Table';

const MULTITASK_DATA_FIELDS = {
  userName: "userName",
  pickedItems: "pickedItems",
  itemsMoved: "itemsMoved",
  itemsSent: "itemsSent",
  itemsBooked: "itemsBooked",
  itemsReturned: "itemsReturned"
}

const MultiTaskDataTable = () => {

  const { t } = useTranslation();

  const [selection, setSelection] = useState(
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection',
    }
  )


  const getData = useAction(() => getMultiTaskData(selection.startDate, selection.endDate), [selection]);
  const data = useSelector(dashboardSelectors.multiTaskData);
  const isLoadingMultiTaskData = useSelector(dashboardSelectors.isLoadingMultiTaskData);

  useEffect(() => {
    getData();
  }, [selection])

  const onDateRangeChange = (which) => {
    setSelection(which.selection)
  }


  // const data = [
  //   {
  //     "userName": "User1",
  //     "pickedItems": 0,
  //     "itemsMoved": 3,
  //     "itemsSent": 5,
  //     "itemsBooked": 1,
  //     "itemsReturned": 25,
  //   },
  //   {
  //     "userName": "Admin",
  //     "pickedItems": 23,
  //     "itemsMoved": 8,
  //     "itemsSent": 4,
  //     "itemsBooked": 4,
  //     "itemsReturned": 9
  //   },
  //   {
  //     "userName": "User3",
  //     "pickedItems": 5,
  //     "itemsMoved": 1,
  //     "itemsSent": 5,
  //     "itemsBooked": 2,
  //     "itemsReturned": 20
  //   },
  //   {
  //     "userName": "Jack",
  //     "pickedItems": 40,
  //     "itemsMoved": 6,
  //     "itemsSent": 10,
  //     "itemsBooked": 4,
  //     "itemsReturned": 12
  //   },
  //   {
  //     "userName": "User5",
  //     "pickedItems": 5,
  //     "itemsMoved": 0,
  //     "itemsSent": 5,
  //     "itemsBooked": 4,
  //     "itemsReturned": 36
  //   },
  //   {
  //     "userName": "User6",
  //     "pickedItems": 18,
  //     "itemsMoved": 2,
  //     "itemsSent": 6,
  //     "itemsBooked": 4,
  //     "itemsReturned": 40
  //   }, {
  //     "userName": "Tom",
  //     "pickedItems": 12,
  //     "itemsMoved": 2,
  //     "itemsSent": 3,
  //     "itemsBooked": 1,
  //     "itemsReturned": 38
  //   },
  //   {
  //     "userName": "User8",
  //     "pickedItems": 18,
  //     "itemsMoved": 6,
  //     "itemsSent": 2,
  //     "itemsBooked": 1,
  //     "itemsReturned": 80
  //   },
  //   {
  //     "userName": "User9",
  //     "pickedItems": 40,
  //     "itemsMoved": 10,
  //     "itemsSent": 18,
  //     "itemsBooked": 5,
  //     "itemsReturned": 21
  //   },
  //   {
  //     "userName": "User10",
  //     "pickedItems": 35,
  //     "itemsMoved": 4,
  //     "itemsSent": 3,
  //     "itemsBooked": 6,
  //     "itemsReturned": 8
  //   },
  //   {
  //     "userName": "Bob",
  //     "pickedItems": 23,
  //     "itemsMoved": 6,
  //     "itemsSent": 12,
  //     "itemsBooked": 4,
  //     "itemsReturned": 34
  //   },
  //   {
  //     "userName": "User12",
  //     "pickedItems": 37,
  //     "itemsMoved": 14,
  //     "itemsSent": 3,
  //     "itemsBooked": 6,
  //     "itemsReturned": 36
  //   },
  //   {
  //     "userName": "User13",
  //     "pickedItems": 14,
  //     "itemsMoved": 3,
  //     "itemsSent": 8,
  //     "itemsBooked": 3,
  //     "itemsReturned": 10
  //   },
  //   {
  //     "userName": "User14",
  //     "pickedItems": 12,
  //     "itemsMoved": 4,
  //     "itemsSent": 23,
  //     "itemsBooked": 12,
  //     "itemsReturned": 18
  //   }, {
  //     "userName": "User15",
  //     "pickedItems": 50,
  //     "itemsMoved": 18,
  //     "itemsSent": 23,
  //     "itemsBooked": 18,
  //     "itemsReturned": 23
  //   },
  //   {
  //     "userName": "User16",
  //     "pickedItems": 10,
  //     "itemsMoved": 15,
  //     "itemsSent": 2,
  //     "itemsBooked": 7,
  //     "itemsReturned": 16
  //   }
  // ]

  const onSort = (a, b, name) => a[name] - b[name]
  const columns = [
    {
      title: t('multiTaskData.fields.userName'),
      dataIndex: MULTITASK_DATA_FIELDS.userName,
      sorter: {
        compare: (a, b) => a.userName.localeCompare(b.userName)
      }
    },
    {
      title: t('multiTaskData.fields.pickedItems'),
      dataIndex: MULTITASK_DATA_FIELDS.pickedItems,
      sorter: {
        compare: (a, b) => onSort(a, b, MULTITASK_DATA_FIELDS.pickedItems)
      }
    },
    {
      title: t('multiTaskData.fields.itemsMoved'),
      dataIndex: MULTITASK_DATA_FIELDS.itemsMoved,
      sorter: {
        compare: (a, b) => onSort(a, b, MULTITASK_DATA_FIELDS.itemsMoved)
      }
    },
    {
      title: t('multiTaskData.fields.itemsSent'),
      dataIndex: MULTITASK_DATA_FIELDS.itemsSent,
      sorter: {
        compare: (a, b) => onSort(a, b, MULTITASK_DATA_FIELDS.itemsSent)
      }
    },
    {
      title: t('multiTaskData.fields.itemsBookedIn'),
      dataIndex: MULTITASK_DATA_FIELDS.itemsBooked,
      sorter: {
        compare: (a, b) => onSort(a, b, MULTITASK_DATA_FIELDS.itemsBooked)
      }
    },
    {
      title: t('multiTaskData.fields.itemsReturned'),
      dataIndex: MULTITASK_DATA_FIELDS.itemsReturned,
      sorter: {
        compare: (a, b) => onSort(a, b, MULTITASK_DATA_FIELDS.itemsReturned)
      }
    }
  ]
  return (
    <Grid fluid className="dashboardContainer">
      <Row className="dashboardHeader">
        <Col xs>
          <DateRangePicker
            onChange={onDateRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className={'PreviewArea'}
            months={3}
            ranges={[selection]}
            direction="horizontal"
          />
        </Col>
      </Row>
      <Table columns={columns} data={data} rowKey={MULTITASK_DATA_FIELDS.userName} loading={isLoadingMultiTaskData} />
    </Grid>
  );
};

export default MultiTaskDataTable;