import React, { useEffect, useState } from 'react';
import BarChart from '../../core/Components/Barchart';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useTranslation } from '../../core';
import { DateRangePicker } from "../../core/Components/DateRange";
import { addDays } from 'date-fns';
import { useSelector } from 'react-redux';
import * as dashboardSelectors from '../selectors';
import { getPickingData } from '../actions';
import _ from 'lodash';
import useAction from '../../core/services/UseAction';
import ChartContainer from '../../core/Components/ChartContainer';
import { withoutNullValue } from '../../core/services/chartDataFilter';

const PickingDataDashboard = () => {

  const { t } = useTranslation();

  const [selection, setSelection] = useState(
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection',
    }
  );

  const [usersSelection, setUserSelection] = useState([]);

  const data = useSelector(dashboardSelectors.pickingData);
  const isLoadingPickingData = useSelector(dashboardSelectors.isLoadingPickingData);
  const getData = useAction(() => getPickingData(selection.startDate, selection.endDate), [selection]);


  useEffect(() => {
    getData();
  }, [selection]);

  const onUserSelection = (userName) => {
    if (_.find(usersSelection, elem => elem === userName)) {
      setUserSelection(_.filter(usersSelection, i => i !== userName))
    } else {
      setUserSelection([...usersSelection, userName])
    }
  }

  // const data = [
  //   {
  //     "userName": "User1",
  //     "pickedOrders": 0,
  //     "ordersPerHour": 3,
  //     "pickedItems": 5,
  //     "itemsPerHour": 1,
  //     "hours": 25,
  //   },
  //   {
  //     "userName": "User2",
  //     "pickedOrders": 23,
  //     "ordersPerHour": 8,
  //     "pickedItems": 4,
  //     "itemsPerHour": 4,
  //     "hours": 9
  //   },
  //   {
  //     "userName": "User3",
  //     "pickedOrders": 5,
  //     "ordersPerHour": 1,
  //     "pickedItems": 5,
  //     "itemsPerHour": 2,
  //     "hours": 20
  //   },
  //   {
  //     "userName": "User4",
  //     "pickedOrders": 40,
  //     "ordersPerHour": 6,
  //     "pickedItems": 10,
  //     "itemsPerHour": 4,
  //     "hours": 12
  //   },
  //   {
  //     "userName": "User5",
  //     "pickedOrders": 5,
  //     "ordersPerHour": 0,
  //     "pickedItems": 5,
  //     "itemsPerHour": 4,
  //     "hours": 36
  //   },
  //   {
  //     "userName": "User6",
  //     "pickedOrders": 18,
  //     "ordersPerHour": 2,
  //     "pickedItems": 6,
  //     "itemsPerHour": 4,
  //     "hours": 40
  //   }, {
  //     "userName": "User7",
  //     "pickedOrders": 12,
  //     "ordersPerHour": 2,
  //     "pickedItems": 3,
  //     "itemsPerHour": 1,
  //     "hours": 38
  //   },
  //   {
  //     "userName": "User8",
  //     "pickedOrders": 18,
  //     "ordersPerHour": 6,
  //     "pickedItems": 2,
  //     "itemsPerHour": 1,
  //     "hours": 80
  //   },
  //   {
  //     "userName": "User9",
  //     "pickedOrders": 40,
  //     "ordersPerHour": 10,
  //     "pickedItems": 18,
  //     "itemsPerHour": 5,
  //     "hours": 21
  //   },
  //   {
  //     "userName": "User10",
  //     "pickedOrders": 35,
  //     "ordersPerHour": 4,
  //     "pickedItems": 3,
  //     "itemsPerHour": 6,
  //     "hours": 8
  //   },
  //   {
  //     "userName": "User11",
  //     "pickedOrders": 23,
  //     "ordersPerHour": 6,
  //     "pickedItems": 12,
  //     "itemsPerHour": 4,
  //     "hours": 34
  //   },
  //   {
  //     "userName": "User12",
  //     "pickedOrders": 37,
  //     "ordersPerHour": 14,
  //     "pickedItems": 3,
  //     "itemsPerHour": 6,
  //     "hours": 36
  //   },
  //   {
  //     "userName": "User13",
  //     "pickedOrders": 14,
  //     "ordersPerHour": 3,
  //     "pickedItems": 8,
  //     "itemsPerHour": 3,
  //     "hours": 10
  //   },
  //   {
  //     "userName": "User14",
  //     "pickedOrders": 12,
  //     "ordersPerHour": 4,
  //     "pickedItems": 23,
  //     "itemsPerHour": 12,
  //     "hours": 18
  //   }, {
  //     "userName": "User15",
  //     "pickedOrders": 50,
  //     "ordersPerHour": 18,
  //     "pickedItems": 23,
  //     "itemsPerHour": 18,
  //     "hours": 23
  //   },
  //   {
  //     "userName": "User16",
  //     "pickedOrders": 10,
  //     "ordersPerHour": 15,
  //     "pickedItems": 2,
  //     "itemsPerHour": 7,
  //     "hours": 16
  //   }
  // ]

  const onDateRangeChange = (which) => {
    setSelection(which.selection);
  }

  return (
    <Grid fluid className="dashboardContainer">
      <Row className="dashboardHeader">
        <Col xs>
          <DateRangePicker
            onChange={onDateRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className={'PreviewArea'}
            months={3}
            ranges={[selection]}
            direction="horizontal"
          />
        </Col>
      </Row>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'pickedOrders')}
          loading={isLoadingPickingData}
          title={t("pickingData.fields.pickedOrders")}
          keys={['pickedOrders']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'pickedItems')}
          loading={isLoadingPickingData}
          title={t("pickingData.fields.pickedItems")}
          keys={['pickedItems']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'ordersPerHour')}
          loading={isLoadingPickingData}
          title={t("pickingData.fields.ordersPerHour")}
          keys={['ordersPerHour']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'itemsPerHour')}
          loading={isLoadingPickingData}
          title={t("pickingData.fields.itemsPerHour")}
          keys={['itemsPerHour']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'hours')}
          loading={isLoadingPickingData}
          title={t("pickingData.fields.hours")}
          keys={['hours']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
    </Grid>
  );
};

export default PickingDataDashboard;