import React, { useEffect, useRef, useState } from 'react';
import { Grid, } from 'react-flexbox-grid';
import { useTranslation } from '../../core';
import { useSelector } from 'react-redux';
import * as dashboardSelectors from '../selectors';
import { getLocation, getSuppliers, getStockConsumptionRateReport } from '../actions';
import _ from 'lodash';
import useAction from '../../core/services/UseAction';
import { Button, Card, DatePicker, Select, Tooltip, InputNumber } from 'antd';
import moment from 'moment'
import { StyleSheet, css } from 'aphrodite';
import ReportView from './ReportsView';
import { CSVLink } from 'react-csv';
import { parseData } from '../../core/services/chartDataFilter';
const { Option } = Select

const Reports = () => {

  const { t } = useTranslation();
  const csvInstance = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState("All");
  const [selectedProductType, setSelectedProductType] = useState("All");
  const [daysStockToHold, setDaysStockToHold] = useState(0);

  const [dateRange, setDateRange] = useState([moment().add(-1, "month").startOf('month'), moment().add(-1, "month").endOf('month')]);
  const [showReport, setShowReport] = useState(false);

  const getData = useAction((csvInstance) => getStockConsumptionRateReport(dateRange[0], dateRange[1], selectedLocation, selectedSupplier, selectedProductType, daysStockToHold, csvInstance), 
    [selectedLocation, selectedSupplier, selectedProductType, daysStockToHold]);
  const data = useSelector(dashboardSelectors.getStockConsumptionRateReportData);
  const isLoadingStockConsumptionRateReportData = useSelector(dashboardSelectors.isLoadingStockConsumptionRateReportData);

  const getLocationData = useAction(() => getLocation());
  const locationData = useSelector(dashboardSelectors.getLocationData);
  const isLoadingLocationData = useSelector(dashboardSelectors.isLoadingLocationData);

  const getSuppliersData = useAction(() => getSuppliers());
  const suppliersData = useSelector(dashboardSelectors.getSuppliersData);
  const isLoadingSuppliersData = useSelector(dashboardSelectors.isLoadingSuppliersData);

  const disableBtn = isLoadingSuppliersData || isLoadingLocationData || isLoadingStockConsumptionRateReportData || !dateRange || !selectedSupplier || !selectedLocation

  const productTypes = [t("reports.productTypes.all"), t("reports.productTypes.standard"), t("reports.productTypes.component")];

  useEffect(() => {
    getSuppliersData();
    getLocationData();
  }, [])

  useEffect(() => {
    if (!selectedLocation && locationData) {
      setSelectedLocation(locationData[0])
    }
  }, [locationData])

  const locationSelect =
    <>
      <Select style={{ width: 266 }} loading={isLoadingLocationData} value={selectedLocation} onChange={(value) => setSelectedLocation(value)}>
        {_.map(locationData, (elem) => <Option value={elem}>{elem}</Option>)}
      </Select>
    </>

  const supplierSelect =
    <>
      <Select style={{ width: 266 }} loading={isLoadingSuppliersData} value={selectedSupplier} onChange={(value) => setSelectedSupplier(value)}>
        {_.map(suppliersData, (elem) => <Option value={elem}>{elem}</Option>)}
      </Select>
    </>

  const productTypeSelect = 
  <>
    <Select style={{ width: 266 }} value={selectedProductType} onChange={(value) => setSelectedProductType(value)}>
      {_.map(productTypes, (elem) => <Option value={elem}>{elem}</Option>)}
    </Select>
  </>

  const daysStockToHoldInput =
  <>
    <InputNumber type='number' style={{ width: 266 }} min={0} value={daysStockToHold} onChange={(value) => setDaysStockToHold(value)} />
  </>

  const openReport = () => {
    getData();
    setShowReport(true);
  }

  const closeReport = () => {
    setShowReport(false);
  }

  const download = (event, done) => {
    getData(csvInstance);
  }

  return (
    <>
      <Grid fluid className="dashboardContainer">
        <Card title={t("reports.stockConsumptionRateReport")}>
          <div>
            <div className={css(styles.inputContainer)}>
              <p className={css(styles.label)}>{t("reports.date")}: </p>
              {moment().format("MM/DD/YYYY h:mm A")}
            </div>
            <div className={css(styles.inputContainer)}>
              <p className={css(styles.label)}>{t("reports.dateRange")}: </p>
              <DatePicker.RangePicker
                format='MM/DD/YYYY'
                onChange={value => setDateRange(value)}
                value={dateRange}
                disabledDate={time => moment(time).isAfter(moment())}
              />
            </div>
            <div className={css(styles.inputContainer)}>
              <p className={css(styles.label)}>
                {t("reports.location")}:
              </p>
              {locationSelect}
            </div>
            <div className={css(styles.inputContainer)}>
              <p className={css(styles.label)}>
                {t("reports.supplier")}:
              </p>
              {supplierSelect}
            </div>
            <div className={css(styles.inputContainer)}>
              <p className={css(styles.label)}>
                {t("reports.productType")}:
              </p>
              {productTypeSelect}
            </div>
            <div className={css(styles.inputContainer)}>
              <p className={css(styles.label)}>
                {t("reports.daysStockToHold")}:
              </p>
              {daysStockToHoldInput}
            </div>
            <div className={css(styles.btnContainer)}>
              <Tooltip title={!dateRange ? t("reports.fillDateRange") : ""}>
                <Button loading={isLoadingStockConsumptionRateReportData} disabled={disableBtn} onClick={openReport}>{t("reports.showReport")}</Button>
              </Tooltip>
              <CSVLink ref={csvInstance} data={parseData(data)} asyncOnClick={true} filename={`ReportDetails${moment().format("MM/DD/YYYY hh:mm")}`}>
              </CSVLink>
              <Tooltip title={!dateRange ? t("reports.fillDateRange") : ""}>
                <Button onClick={download} loading={isLoadingStockConsumptionRateReportData} disabled={disableBtn}>{t("reports.downloadReport")}</Button>
              </Tooltip>
            </div>
          </div>
        </Card>
      </Grid>
      <ReportView data={data} loading={isLoadingStockConsumptionRateReportData} closeReport={closeReport} showReport={showReport} />
    </>
  );
};

export default Reports;

const styles = StyleSheet.create({
  btnContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  label: {
    width: 150,
    margin: 0
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 4,
  }
})