import axios from 'axios';

export const endpoints = {
    getToken: () => `/auth`
};

export const autorizeByApplication = token => {
    return axios.get(endpoints.getToken(), {
        headers: {
            token
        }
    });
};
