import _ from "lodash";
import { isLoading } from "../core/selectors";
import { NAME } from "./constants";
import * as actionTypes from './actionTypes';

export const getDashboardLayout = state => state[NAME].layout;

export const getAppSetings = state => state[NAME].settings;
export const isApplyFiltersToAllCharts = state => getAppSetings(state).applyFilterschartFilters;

export const pickingData = state => _.get(state[NAME], 'pickingData');
export const isLoadingPickingData = state => isLoading(state, actionTypes.GET_PICKING_DATA.NAME);

export const sendingData = state => _.get(state[NAME], 'sendingData');
export const isLoadingSendingData = state => isLoading(state, actionTypes.GET_SENDING_DATA.NAME);

export const stockMoveData = state => _.get(state[NAME], 'stockMoveData');
export const isLoadingStockMoveData = state => isLoading(state, actionTypes.GET_STOCK_MOVE_DATA.NAME);

export const doodsInData = state => _.get(state[NAME], 'goodsInData');
export const isLoadingGoodsInData = state => isLoading(state, actionTypes.GET_GOODS_IN_DATA.NAME);

export const returnsData = state => _.get(state[NAME], 'returnsData');
export const isLoadingReturnsData = state => isLoading(state, actionTypes.GET_RETURNS_DATA.NAME);

export const multiTaskData = state => _.get(state[NAME], 'multiTaskData');
export const isLoadingMultiTaskData = state => isLoading(state, actionTypes.GET_MULTI_TASK_DATA.NAME);

export const stockCountData = state => _.get(state[NAME], 'stockCountData');
export const isLoadingStockCountData = state => isLoading(state, actionTypes.GET_STOCK_COUNT_DATA.NAME);

export const getLocationData = state => _.get(state[NAME], 'locationsData');
export const isLoadingLocationData = state => isLoading(state, actionTypes.GET_LOCATION_DATA.NAME);

export const getSuppliersData = state => _.get(state[NAME], 'suppliersData');
export const isLoadingSuppliersData = state => isLoading(state, actionTypes.GET_SUPPLIERS_DATA.NAME);

export const getStockConsumptionRateReportData = state => _.get(state[NAME], 'stockConsumptionRateReport');
export const isLoadingStockConsumptionRateReportData = state => isLoading(state, actionTypes.GET_STOCK_CONSUMPTION_RATE_REPORT.NAME);