import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { connect } from "react-redux";
import Loader from './Loader';
import ChartWrapper from '../../core/Components/ChartWrapper/ChartWrapper';
import _ from "lodash";
import * as dashboardSelectors from '../../Dashboard/selectors';

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.props.data });
        }
        if (!_.isEqual(prevProps.usersSelection, this.props.usersSelection) && this.props.isApplyFiltersToAllCharts) {
            this.setState({ ...this.state, data: _.filter(this.props.data, elem => !this.props.usersSelection.includes(elem.userName)) })
        }
    }

    onSelectItems = data => {
        !this.props.isApplyFiltersToAllCharts && this.setState({ data });
    };

    render() {
        const { data, indexBy, loading, onClick, title, textDisplayKey, description, groupMode, valuePrefix, colors, keys, onUserSelection, usersSelection, isApplyFiltersToAllCharts } = this.props;
        let content;
        if (loading) {
            content = <Loader loading={loading} />;
        }
        if (data && (!data.length || !this.state.data.length) && !loading) {
            content = <p className={'noDataMessage'}>Not enough data to show you this great chart :(</p>;
        }
        let total = 0;
        if (data && data.length > 0 && !loading && this.state.data.length) {
            content = (<ResponsiveBar
                data={this.state.data}
                keys={keys}
                indexBy={indexBy}
                margin={{ top: 50, right: 130, bottom: 60, left: 60 }}
                padding={0.15}
                colors={colors ? colors : ['#4292c6', '#9ecae1']}
                maxValue={'auto'}
                onClick={onClick}
                groupMode={groupMode ? groupMode : "stacked"}
                tooltip={({ id, value, data }) => {
                    return (
                        <div>
                            <p><strong>{data[textDisplayKey]}</strong></p>
                            <p>{id.replace(/([A-Z])/g, ' $1').trim()}: {valuePrefix ? valuePrefix + value : value}</p>
                            {data.total && <p>Total: {data.total}</p>}
                        </div>);
                }}
                // fill={[
                //     {
                //         match: {
                //             id: 'processed'
                //         },
                //         id: 'processed'
                //     },
                //     {
                //         match: {
                //             id: 'opened'
                //         },
                //         id: 'opened'
                //     }
                // ]}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -15,
                    legendPosition: 'middle',
                    legendOffset: 52
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={16}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 25,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 20,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        // wordWrap: "break-word",
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
            />);
            total = this.state.data.reduce((grandsum, item) => {
                return grandsum + keys.reduce((sum, key) => sum + parseFloat(item[key] || 0), 0)
            }, 0);
        }

        return (
            <ChartWrapper title={title} description={description} data={data} onSelectItems={this.onSelectItems} textDisplayKey={textDisplayKey} total={total} onUserSelection={onUserSelection} usersSelection={usersSelection} applyFiltersToAll={isApplyFiltersToAllCharts} loading={loading}>
                {content}
            </ChartWrapper>
        );
    }
}

function mapStateToProps(state) {
    const isApplyFiltersToAllCharts = dashboardSelectors.isApplyFiltersToAllCharts(state)
    return {
        isApplyFiltersToAllCharts
    }
}

export default connect(mapStateToProps, null)(BarChart);
