import i18next from 'i18next';
import moment from "moment";
import en from './translations/en.json';

i18next.init({
    interpolation: {
        escapeValue: false
    },
    lng: 'en',
    resources: {
        en: {
            translation: en
        }
    },
    returnObjects: true
});

moment.updateLocale("en", {
    week: {
        dow: 1
    }
});

export default i18next;