import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { addDays } from 'date-fns';
import { useSelector } from 'react-redux';
import * as dashboardSelectors from '../selectors';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useTranslation } from '../../core';
import { DateRangePicker } from "../../core/Components/DateRange";
import { getStockCountData } from '../actions';
import { withoutNullValue } from '../../core/services/chartDataFilter';
import useAction from '../../core/services/UseAction';
import BarChart from '../../core/Components/Barchart';
import ChartContainer from '../../core/Components/ChartContainer';


const StockCountDataDashboard = () => {

  const { t } = useTranslation();

  const [selection, setSelection] = useState(
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection',
    }
  )

  const [usersSelection, setUserSelection] = useState([]);

  const getData = useAction(() => getStockCountData(selection.startDate, selection.endDate), [selection]);
  const data = useSelector(dashboardSelectors.stockCountData);
  const isLoadingStockCountData = useSelector(dashboardSelectors.isLoadingStockCountData);


  useEffect(() => {
    getData();
  }, [selection])

  const onDateRangeChange = (which) => {
    setSelection(which.selection)
  }


  const onUserSelection = (userName) => {
    if (_.find(usersSelection, elem => elem === userName)) {
      setUserSelection(_.filter(usersSelection, i => i !== userName))
    } else {
      setUserSelection([...usersSelection, userName])
    }
  }

  return (
    <Grid fluid className="dashboardContainer">
      <Row className="dashboardHeader">
        <Col xs>
          <DateRangePicker
            onChange={onDateRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className={'PreviewArea'}
            months={3}
            ranges={[selection]}
            direction="horizontal"
          />
        </Col>
      </Row>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'itemsBooked')}
          loading={isLoadingStockCountData}
          title={t("stockCountData.fields.itemsBooked")}
          keys={['itemsBooked']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'itemsBookedPerHour')}
          loading={isLoadingStockCountData}
          title={t("stockCountData.fields.itemsBookedPerHour")}
          keys={['itemsBookedPerHour']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'hours')}
          loading={isLoadingStockCountData}
          title={t("stockCountData.fields.hours")}
          keys={['hours']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
    </Grid>
  );
};

export default StockCountDataDashboard;