import { Table } from 'antd';
import _ from 'lodash';
import React from 'react';
import { MATH_ACTIONS } from '../constants';

const { Summary } = Table

const SummaryRow = ({ data, columns }) => {

  const renderCell = () => {

    return _.map(columns, (elem) => {
      const value = countValues(elem.summary, _.map(data, e => _.get(e, elem.dataIndex)))
      return elem.summary ? <Summary.Cell>{value}</Summary.Cell> : <></>
    });

  }


  const countValues = (mathAction, values) => {
    switch (mathAction) {
      case MATH_ACTIONS.SUM:
        return _.reduce(values, (sum, n) => (sum + n), 0);
      case MATH_ACTIONS.ROUND:
        return _.reduce(values, (sum, n) => (sum + n), 0) / values.length;
      default:
        return null
    }
  }

  return (
    <Summary>
      <Summary.Row>
        <Summary.Cell>Total</Summary.Cell>
        {renderCell()}
      </Summary.Row>
    </Summary>
  );
};

export default SummaryRow;