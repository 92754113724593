export const BASE_URL = "https://882-dashboards.brainence.info/api";
export const NAME = 'core';
export const LOADING_NAME = 'loading';
export const ERROR_NAME = 'error';



export const MATH_ACTIONS = {
  SUM: "SUM",
  ROUND: "ROUND",
}