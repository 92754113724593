/* eslint-disable react/prop-types */
import { Button, Modal } from "antd";
import PropTypes from 'prop-types';
import React from "react";
import { useTranslation } from "../../core";
import Table from "../../core/Components/Table";

const REPORT_FIELDS = {
    brand: "brand",
    sku: "sku",
    itemTitle: "itemTitle",
    category: "category",
    supplierCode: "supplierCode",
    barcode: "barcode",
    quantitySold: "quantitySold",
    margin: "margin",
    soldValue: "soldValue",
    purchaseValue: "purchaseValue",
    inOpenOrders: "inOpenOrders",
    onOrder: "onOrder",
    pending: "pending",
    availableQty: "availableQty",
    availableStockValue: "availableStockValue",
    minimumLevel: "minimumLevel",
    inStockConsumption: "inStockConsumption",
    availableDays: "availableDays",
    suggestedReorderQuantity: "suggestedReorderQuantity",
    outofStock: "outofStock",
    thirtyDaysSales1MonthPrior: "thirtyDaysSales1MonthPrior",
    oos1MonthPrior: "oos1MonthPrior",
    thirtyDaysSales2MonthPrior: "thirtyDaysSales2MonthPrior",
    oos2MonthPrior: "oos2MonthPrior",
    thirtyDaysSales3MonthPrior: "thirtyDaysSales3MonthPrior",
    oos3MonthPrior: "oos2MonthPrior"
}

const ReportView = ({ data, showReport, closeReport, loading }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('reports.tableFields.brand'),
            dataIndex: REPORT_FIELDS.brand,
        },
        {
            title: t('reports.tableFields.sku'),
            dataIndex: REPORT_FIELDS.sku,
        },
        {
            title: t('reports.tableFields.itemTitle'),
            dataIndex: REPORT_FIELDS.itemTitle,
        },
        {
            title: t('reports.tableFields.category'),
            dataIndex: REPORT_FIELDS.category,
        },
        {
            title: t('reports.tableFields.supplierCode'),
            dataIndex: REPORT_FIELDS.supplierCode,
        },
        {
            title: t('reports.tableFields.barcode'),
            dataIndex: REPORT_FIELDS.barcode,
        },
        {
            title: t('reports.tableFields.quantitySold'),
            dataIndex: REPORT_FIELDS.quantitySold,
        },
        {
            title: t('reports.tableFields.margin'),
            dataIndex: REPORT_FIELDS.margin,
        },
        {
            title: t('reports.tableFields.soldValue'),
            dataIndex: REPORT_FIELDS.soldValue,
        },
        {
            title: t('reports.tableFields.purchaseValue'),
            dataIndex: REPORT_FIELDS.purchaseValue,
        },
        {
            title: t('reports.tableFields.inOpenOrders'),
            dataIndex: REPORT_FIELDS.inOpenOrders,
        },
        {
            title: t('reports.tableFields.onOrder'),
            dataIndex: REPORT_FIELDS.onOrder,
        },
        {
            title: t('reports.tableFields.pending'),
            dataIndex: REPORT_FIELDS.pending,
        },
        {
            title: t('reports.tableFields.availableQty'),
            dataIndex: REPORT_FIELDS.availableQty,
        },
        {
            title: t('reports.tableFields.availableStockValue'),
            dataIndex: REPORT_FIELDS.availableStockValue,
        },
        {
            title: t('reports.tableFields.minimumLevel'),
            dataIndex: REPORT_FIELDS.minimumLevel,
        },
        {
            title: t('reports.tableFields.inStockConsumption'),
            dataIndex: REPORT_FIELDS.inStockConsumption,
            render: (text) => Number(text).toFixed(2)
        },
        {
            title: t('reports.tableFields.availableDays'),
            dataIndex: REPORT_FIELDS.availableDays,
            render: (text) => Number(text).toFixed(2)
        },
        {
            title: t('reports.tableFields.suggestedReorderQuantity'),
            dataIndex: REPORT_FIELDS.suggestedReorderQuantity,
        },
        {
            title: t('reports.tableFields.outofStock'),
            dataIndex: REPORT_FIELDS.outofStock,
        },
        {
            title: t('reports.tableFields.thirtyDaysSales1MonthPrior'),
            dataIndex: REPORT_FIELDS.thirtyDaysSales1MonthPrior
        },
        {
            title: t('reports.tableFields.oos1MonthPrior'),
            dataIndex: REPORT_FIELDS.oos1MonthPrior,
        },
        {
            title: t('reports.tableFields.thirtyDaysSales2MonthPrior'),
            dataIndex: REPORT_FIELDS.thirtyDaysSales2MonthPrior,
        },
        {
            title: t('reports.tableFields.oos2MonthPrior'),
            dataIndex: REPORT_FIELDS.oos2MonthPrior,
        },
        {
            title: t('reports.tableFields.thirtyDaysSales3MonthPrior'),
            dataIndex: REPORT_FIELDS.thirtyDaysSales3MonthPrior
        },
        {
            title: t('reports.tableFields.oos3MonthPrior'),
            dataIndex: REPORT_FIELDS.oos3MonthPrior,
        }
    ];

    const footer = () => (
        <>
            <Button key="1" onClick={closeReport}>
                {t("core.cancel")}
            </Button>
        </>
    );

    return (
        <Modal
            visible={
                showReport
            }
            footer={[footer()]}
            onCancel={closeReport}
            title={t("reports.reportDetails")}
            width={"90%"}>
            <div>
                <Table
                    columns={columns}
                    data={data}
                    loading={loading}
                    scroll={{ x: "100vw" }}
                />
            </div>
        </Modal>
    );
};

ReportView.propTypes = {
    data: PropTypes.array.isRequired,
    showReport: PropTypes.bool.isRequired,
    closeReport: PropTypes.func.isRequired,
    loading: PropTypes.func.isRequired
};


export default ReportView;