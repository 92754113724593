import React, { useEffect, useState } from 'react';
import BarChart from '../../core/Components/Barchart';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useTranslation } from '../../core';
import { DateRangePicker } from "../../core/Components/DateRange";
import { addDays } from 'date-fns';
import { useSelector } from 'react-redux';
import * as dashboardSelectors from '../selectors';
import { getSendingData } from '../actions';
import _ from 'lodash';
import useAction from '../../core/services/UseAction';
import ChartContainer from '../../core/Components/ChartContainer';
import { withoutNullValue } from '../../core/services/chartDataFilter';


const SendingDataDashboard = () => {

  const { t } = useTranslation();

  const [selection, setSelection] = useState(
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection',
    }
  )
  const [usersSelection, setUserSelection] = useState([]);

  const getData = useAction(() => getSendingData(selection.startDate, selection.endDate), [selection]);

  const data = useSelector(dashboardSelectors.sendingData);

  const isLoadingPickingData = useSelector(dashboardSelectors.isLoadingSendingData);

  useEffect(() => {
    getData();
  }, [selection])

  const onDateRangeChange = (which) => {
    setSelection(which.selection)
  }

  const onUserSelection = (userName) => {
    if (_.find(usersSelection, elem => elem === userName)) {
      setUserSelection(_.filter(usersSelection, i => i !== userName))
    } else {
      setUserSelection([...usersSelection, userName])
    }
  }

  // const data = [
  //   {
  //     "userName": "User1",
  //     "ordersSent": 0,
  //     "ordersSentPerHour": 3,
  //     "itemsSent": 5,
  //     "itemsSentPerHour": 1,
  //     "hours": 25,
  //   },
  //   {
  //     "userName": "User2",
  //     "ordersSent": 23,
  //     "ordersSentPerHour": 8,
  //     "itemsSent": 4,
  //     "itemsSentPerHour": 4,
  //     "hours": 0
  //   },
  //   {
  //     "userName": "User3",
  //     "ordersSent": 5,
  //     "ordersSentPerHour": 1,
  //     "itemsSent": 5,
  //     "itemsSentPerHour": 2,
  //     "hours": 20
  //   },
  //   {
  //     "userName": "User4",
  //     "ordersSent": 40,
  //     "ordersSentPerHour": 6,
  //     "itemsSent": 10,
  //     "itemsSentPerHour": 4,
  //     "hours": 12
  //   },
  //   {
  //     "userName": "User5",
  //     "ordersSent": 5,
  //     "ordersSentPerHour": 0,
  //     "itemsSent": 5,
  //     "itemsSentPerHour": 4,
  //     "hours": 36
  //   },
  //   {
  //     "userName": "User6",
  //     "ordersSent": 0,
  //     "ordersSentPerHour": 2,
  //     "itemsSent": 6,
  //     "itemsSentPerHour": 4,
  //     "hours": 40
  //   }, {
  //     "userName": "User7",
  //     "ordersSent": 12,
  //     "ordersSentPerHour": 2,
  //     "itemsSent": 3,
  //     "itemsSentPerHour": 1,
  //     "hours": 38
  //   },
  //   {
  //     "userName": "User8",
  //     "ordersSent": 18,
  //     "ordersSentPerHour": 6,
  //     "itemsSent": 2,
  //     "itemsSentPerHour": 1,
  //     "hours": 80
  //   },
  //   {
  //     "userName": "User9",
  //     "ordersSent": 40,
  //     "ordersSentPerHour": 10,
  //     "itemsSent": 18,
  //     "itemsSentPerHour": 5,
  //     "hours": 21
  //   },
  //   {
  //     "userName": "User10",
  //     "ordersSent": 35,
  //     "ordersSentPerHour": 4,
  //     "itemsSent": 30,
  //     "itemsSentPerHour": 6,
  //     "hours": 8
  //   },
  //   {
  //     "userName": "User11",
  //     "ordersSent": 23,
  //     "ordersSentPerHour": 6,
  //     "itemsSent": 0,
  //     "itemsSentPerHour": 4,
  //     "hours": 34
  //   },
  //   {
  //     "userName": "User12",
  //     "ordersSent": 37,
  //     "ordersSentPerHour": 14,
  //     "itemsSent": 3,
  //     "itemsSentPerHour": 6,
  //     "hours": 36
  //   },
  //   {
  //     "userName": "User13",
  //     "ordersSent": 14,
  //     "ordersSentPerHour": 3,
  //     "itemsSent": 8,
  //     "itemsSentPerHour": 3,
  //     "hours": 10
  //   },
  //   {
  //     "userName": "User14",
  //     "ordersSent": 12,
  //     "ordersSentPerHour": 4,
  //     "itemsSent": 23,
  //     "itemsSentPerHour": 12,
  //     "hours": 18
  //   }, {
  //     "userName": "User15",
  //     "ordersSent": 50,
  //     "ordersSentPerHour": 18,
  //     "itemsSent": 23,
  //     "itemsSentPerHour": 18,
  //     "hours": 23
  //   },
  //   {
  //     "userName": "User16",
  //     "ordersSent": 0,
  //     "ordersSentPerHour": 15,
  //     "itemsSent": 2,
  //     "itemsSentPerHour": 7,
  //     "hours": 16
  //   }
  // ]

  return (
    <Grid fluid className="dashboardContainer">
      <Row className="dashboardHeader">
        <Col xs>
          <DateRangePicker
            onChange={onDateRangeChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className={'PreviewArea'}
            months={3}
            ranges={[selection]}
            direction="horizontal"
          />
        </Col>
      </Row>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'ordersSent')}
          loading={isLoadingPickingData}
          title={t("sendingData.fields.ordersSent")}
          keys={['ordersSent']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'ordersSentPerHour')}
          loading={isLoadingPickingData}
          title={t("sendingData.fields.ordersSentPerHour")}
          keys={['ordersSentPerHour']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'itemsSent')}
          loading={isLoadingPickingData}
          title={t("sendingData.fields.itemsSent")}
          keys={['itemsSent']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'itemsSentPerHour')}
          loading={isLoadingPickingData}
          title={t("sendingData.fields.itemsSentPerHour")}
          keys={['itemsSentPerHour']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
      <ChartContainer>
        <BarChart
          indexBy={'userName'}
          data={withoutNullValue(data, 'hours')}
          loading={isLoadingPickingData}
          title={t("sendingData.fields.hours")}
          keys={['hours']}
          textDisplayKey="userName"
          onUserSelection={onUserSelection}
          usersSelection={usersSelection}
        />
      </ChartContainer>
    </Grid>
  );
};

export default SendingDataDashboard;