import produce from 'immer';
import { combineReducers } from 'redux';
import _ from 'lodash';
import * as types from './actionTypes';
import { LOADING_NAME, ERROR_NAME } from './constants';

const loadingInitialState = {};
const loadingReducer = (state = loadingInitialState, action) => {
    const { type } = action;
    if (type === types.RESET) return loadingInitialState;
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type);

    if (!matches) return state;

    const [, requestName, requestState] = matches;
    const propName = _.has(action, 'requestId') ? `${requestName}/${action.requestId}` : requestName;

    return requestState === 'REQUEST' ? {
        ...state,
        [propName]: true
    } : _.omit(state, [propName]);
};

const getPropName = action => (!!_.get(action, 'requestId') ? `${action.payload.actionType}/${action.requestId}` : action.payload.actionType);

const errorInitialState = {};
const errorReducer = produce((state = errorInitialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case types.ADD_ERROR: {
                draft[getPropName(action)] = action.payload.error;
                return;
            }
            case types.REMOVE_ERROR: {
                draft[getPropName(action)] = null;
                return;
            }
            case types.RESET:
                return errorInitialState;
            default:
                return;
        }
    });
});

export default combineReducers({
    [LOADING_NAME]: loadingReducer,
    [ERROR_NAME]: errorReducer,
});