import React from 'react';
import ReactClearCache from 'react-clear-cache';
import { Button } from 'reactstrap';
import './ClearCache.css';

const ClearCache = React.memo(() => {
    return (
        <ReactClearCache>
            {({ isLatestVersion, latestVersion, emptyCacheStorage }) => {
                return !isLatestVersion ?
                    <div className="clearCache-container">
                        <p className="clearCache-title">New version available!</p>
                        <p className="clearCache-description">Please reload page to use the latest changes.</p>
                        <Button
                            color="primary"
                            onClick={() => emptyCacheStorage(latestVersion)}>
                            Reload
                        </Button>
                    </div> : null;
            }}
        </ReactClearCache>
    );
});

export default ClearCache;