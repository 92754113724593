import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Col, Grid, Row } from "react-flexbox-grid";
import { Button } from 'reactstrap';
import { useTranslation } from '../../core';
import Checkbox from '../../core/Components/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { setAppSettings } from '../actions';
import * as dashboardSelectors from '../selectors';

const Settings = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const appSetings = useSelector(dashboardSelectors.getAppSetings)

  const [settings, setSettings] = useState(appSetings)

  const setNewSettings = () => dispatch(setAppSettings(settings));


  const handleSubmit = () => {
    setNewSettings();
  }

  const onFilterChange = () => {
    setSettings({
      ...settings,
      applyFilterschartFilters: !settings.applyFilterschartFilters
    })
  }

  return (
    <div className={css(styles.container)}>
      {settings ? <Grid fluid>
        <Row className={css(styles.group)}>
          {t("settings.fields.general")}
        </Row>
        <Row className={css(styles.inputContainer)}>
          <Col>
            <Checkbox checked={settings.applyFilterschartFilters} onChange={onFilterChange}>{t("settings.fields.applyFilters")}</Checkbox>
          </Col>
        </Row>
        <Row>
          <Button className={css(styles.button)} onClick={handleSubmit}>{t("settings.fields.saveChanges")}</Button>
        </Row>
      </Grid> : null}
    </div>
  );
};

export default Settings;

const styles = StyleSheet.create({
  container: {
    padding: 16
  },
  button: {
    backgroundColor: "#007bff",
    borderColor: "#007bff",
    marginTop: 15
  },
  group: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10
  },
  inputContainer: {
    marginLeft: 12

  }
})