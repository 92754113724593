
import React from 'react';
import { Table as AntdTable } from 'antd';
import _ from 'lodash';
import SummaryRow from './SummaryRow';

const Table = ({ columns, data, rowKey, loading, ...props }) => {

  const isContainSummary = _.find(columns, (elem) => elem.summary);
  return <AntdTable
    columns={columns}
    dataSource={data}
    rowKey={rowKey}
    loading={loading}
    pagination={{
      showSizeChanger: true
    }}
    summary={() => (isContainSummary && data.length) ? <SummaryRow data={data} columns={columns} /> : null}
    {...props}
  />
}


export default Table