import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import dashboard from './Modules/Dashboard/reducer';
import auth from './Modules/Auth/reducer';
import core from './Modules/core/reducer';


export default function configureStore(history, initialState) {
    const reducers = {
        dashboard,
        auth,
        core
    };

    let middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const isDevelopment = process.env.NODE_ENV === 'development';

    if (isDevelopment) {
        const logger = require('redux-logger').createLogger({ collapsed: true });
        middleware = [...middleware, logger];
    }

    const composeEnhancers = isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    return createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware))
    );
}
