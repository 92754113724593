import _ from 'lodash';
import { NAME, LOADING_NAME, ERROR_NAME } from './constants';

export const isLoading = (state, actionType) => !!state[NAME][LOADING_NAME][actionType];
export const isLoadingWithRequestId = (state, actionType, requestId) => isLoading(state, `${actionType}/${requestId}`);
export const getError = (state, actionType) => state[NAME][ERROR_NAME][actionType];
export const getErrorWithRequestId = (state, actionType, requestId) => state[NAME][ERROR_NAME][`${actionType}/${requestId}`];
export const mapItemsIds = (items, ids) => _.map(ids, id => items[id]);
export const mapItemsIdsWithCheckOnExisting = (items, ids) => _.filter(_.map(ids, id => items[id]), item => item);
export const getItemById = (items, id) => items[id];
export const getSecondParam = (firstParam, secondParam) => secondParam;
export const getThirdParam = (firstParam, secondParam, thirdParam) => thirdParam;
export const getLastAdded = (state, path) => _.last(_.get(state[NAME][NAME], `lastUpdated.${path}`));
export const getAllLastAdded = state => _.get(state[NAME][NAME], `lastUpdated`);

