import axios from 'axios';

export const endpoints = {
    getPickingData: (from, to) => `getPickingData/${from}/${to}`,
    getSendingData: (from, to) => `getSendingData/${from}/${to}`,
    getStockMoveData: (from, to) => `getStockMoveData/${from}/${to}`,
    getGoodsInData: (from, to) => `getGoodsInData/${from}/${to}`,
    getReturnsData: (from, to) => `getReturnsData/${from}/${to}`,
    getMultiTaskData: (from, to) => `getMultiTaskData/${from}/${to}`,
    getStockCountData: (from, to) => `getStockCountData/${from}/${to}`,
    getLocation: () => `report/getLocations`,
    getSuppliers: () => `report/getSuppliers`,
    getStockConsumptionRateReport: (from, to, location, suplier = 'All', productType, daysStockToHold) => {
        const daysToHold = daysStockToHold ? daysStockToHold : '0';
        return `report/getStockConsumptionRateReport/${from}/${to}/${location}/${suplier}/${productType}/${daysToHold}`;
    },
}

export const getPickingData = (from, to) => {
    return axios.get(endpoints.getPickingData(from, to));
}

export const getSendingData = (from, to) => {
    return axios.get(endpoints.getSendingData(from, to))
}

export const getStockMoveData = (from, to) => {
    return axios.get(endpoints.getStockMoveData(from, to))
}

export const getGoodsInData = (from, to) => {
    return axios.get(endpoints.getGoodsInData(from, to))
}

export const getReturnsData = (from, to) => {
    return axios.get(endpoints.getReturnsData(from, to))
}

export const getMultiTaskData = (from, to) => {
    return axios.get(endpoints.getMultiTaskData(from, to))
}

export const getStockCountData = (from, to) => {
    return axios.get(endpoints.getStockCountData(from, to))
}

export const getLocation = () => {
    return axios.get(endpoints.getLocation())
}

export const getSuppliers = () => {
    return axios.get(endpoints.getSuppliers())
}

export const getStockConsumptionRateReport = (from, to, location, suplier, productType, daysStockToHold) => {
    return axios.get(endpoints.getStockConsumptionRateReport(from, to, location, suplier, productType, daysStockToHold))
}