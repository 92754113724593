import { NAME } from './constants';

export const RESET = `${NAME}/RESET`;
export const ADD_ERROR = `${NAME}/ADD_ERROR`;
export const REMOVE_ERROR = `${NAME}/REMOVE_ERROR`;

export const ADD_MENU_OPEN_KEY = `${NAME}/ADD_MENU_OPEN_KEY`;
export const REMOVE_MENU_OPEN_KEY = `${NAME}/REMOVE_MENU_OPEN_KEY`;

export const ADD_LAST_UPDATED = `${NAME}/ADD_LAST_UPDATED`;
export const REMOVE_LAST_UPDATED = `${NAME}/REMOVE_LAST_UPDATED`;