import { useCallback } from "react";
import { useDispatch } from "react-redux";

const useAction = (callback, deps) => {
  const dispatch = useDispatch();

  return useCallback((...values) => {
    dispatch(callback(...values));
  }, [deps]);
};

export default useAction