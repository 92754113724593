import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { NAME } from './constants';
import * as actionTypes from './actionTypes';

const initialState = {
    pickingData: [],
    sendingData: [],
    stockMoveData: [],
    goodsInData: [],
    returnsData: [],
    multiTaskData: [],
    stockCountData: [],
    locationsData: [],
    suppliersData: [],
    stockConsumptionRateReport: [],
    layout: [],
    settings: {
        applyFilterschartFilters: true
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PICKING_DATA.SUCCESS:
            return {
                ...state,
                pickingData: action.payload
            }
        case actionTypes.GET_SENDING_DATA.SUCCESS:
            return {
                ...state,
                sendingData: action.payload
            }
        case actionTypes.GET_STOCK_MOVE_DATA.SUCCESS:
            return {
                ...state,
                stockMoveData: action.payload
            }
        case actionTypes.GET_GOODS_IN_DATA.SUCCESS:
            return {
                ...state,
                goodsInData: action.payload
            }
        case actionTypes.GET_RETURNS_DATA.SUCCESS:
            return {
                ...state,
                returnsData: action.payload
            }
        case actionTypes.GET_MULTI_TASK_DATA.SUCCESS:
            return {
                ...state,
                multiTaskData: action.payload
            }
        case actionTypes.GET_STOCK_COUNT_DATA.SUCCESS:
            return {
                ...state,
                stockCountData: action.payload
            }
        case actionTypes.GET_LOCATION_DATA.SUCCESS:
            return {
                ...state,
                locationsData: action.payload
            }
        case actionTypes.GET_SUPPLIERS_DATA.SUCCESS:
            return {
                ...state,
                suppliersData: ["All", ...action.payload]
            }
        case actionTypes.GET_STOCK_CONSUMPTION_RATE_REPORT.SUCCESS:
            return {
                ...state,
                stockConsumptionRateReport: action.payload
            }
        case actionTypes.UPDATE_DASHBOARD_LAYOUT.SUCCESS:
            return {
                ...state,
                layout: action.payload.data
            };
        case actionTypes.SET_SETTINGS.SUCCESS:
            return {
                ...state,
                settings: action.payload
            };
        default:
            return state;
    }
};

const persistConfig = {
    key: NAME,
    storage,
    whitelist: []
};

export default persistReducer(persistConfig, reducer);