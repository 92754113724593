import _ from "lodash";

export const withoutNullValue = (items, name) => _.filter(items, elem => elem[name] > 0)

export const parseData = (data) => {
  const res = data.map(elem => {
    let obj = {}
    for (var prop in elem) {
      if (_.isString(elem[prop])) {
        obj[prop] = elem[prop].replace('"', "")
      } else {
        obj[prop] = elem[prop]
      }
    }
    return obj
  })
  return res
}