import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const ChartContainer = ({ children }) => {
  return (
    <div className={css(styles.container)}>
      {children}
    </div>
  );
};

export default ChartContainer;

const styles = StyleSheet.create({
  container: {
    height: 500,
    width: "100%",
    marginBottom: 20
  },

})